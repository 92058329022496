import React, { useEffect } from 'react'
import * as d3 from 'd3'

const BarChart = () => {
  const drawChart = data => {
    const canvasHeight = 400
    const canvasWidth = 600
    const scale = 20
    const svgCanvas = d3.select('#canvas')

    svgCanvas
      .append('svg')
      .attr('width', canvasWidth)
      .attr('height', canvasHeight)
      .style('border', '1px solid black')
      .selectAll('rect')
      .data(data)
      .enter()
      .append('rect')
      .attr('width', 40)
      .attr('height', datapoint => datapoint * scale)
      .attr('fill', 'orange')
      .attr('x', (datapoint, iteration) => iteration * 45)
      .attr('y', datapoint => {
        return canvasHeight - datapoint * scale
      })
  }

  useEffect(() => {
    const data = [2, 4, 2, 6, 9]
    drawChart(data)
  })

  return <div id="canvas" />
}

export default BarChart
