import React from 'react';
import Helmet from 'react-helmet';

import BarGraph from '../components/DataVisualization/SimplestBarGraph';

import pic11 from '../assets/images/pic11.jpg';
import Layout from '../components/layout'

const Generic = props => (
  <Layout>
    <Helmet>
      <title>Learning</title>
      <meta name="description" content="Generic Page" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Learning</h1>
          </header>
          <span className="image main">
            <img src={pic11} alt="" />
          </span>
          <BarGraph />
        </div>
      </section>
    </div>
  </Layout>
);

export default Generic;
